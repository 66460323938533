import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../utils/Header.tsx";
import Footer from "../utils/Footer.tsx";

export default function Home() {
    return (
        <>
        <Header />
        <Container>
            <h5>
                Welcome to Code Taught, India !
            </h5>
            <p>Want to become a programmer, You are on the right place</p>
            <p>We help you to learn programming languages which are currently necessary for job perspective</p>
            <p>Below are the list of technologies we can help you</p>
            <p>React JS, Vue JS, Node JS, Angular JS, Python, Java Spring Boot, PHP, Go Lang</p>
            <p>AWS, Azure, Docker, Jenkins</p>
            <p>MySQL, Postgres, MongoDB</p>
            <p>These are the high level scope of the languages we teach, however there are still more than that</p>
            <p>For more details, stay tuned with us or contact us</p>
        </Container>
        {/* <Footer /> */}
        </>
    );
  }