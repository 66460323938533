import React from 'react'
import {
    Navbar,
    Container,
    NavDropdown,
    Nav,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function Header() {
  
    // Collapse isOpen State
    const [isOpen, setIsOpen] = React.useState(false);
  
    return (
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <Container>
        <Navbar.Brand><Link to="/"><img className='main-logo' src='logo.png'/></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            <Nav.Link><Link to="/about">About</Link></Nav.Link>
            <Nav.Link><Link to="/privacy-policy">Privacy Policy</Link></Nav.Link>
            <Nav.Link><Link to="/terms-condition">Terms & Condition</Link></Nav.Link>
            <Nav.Link><Link to="/contact-us">Contact Us</Link></Nav.Link>
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
            </Nav>
            {/* <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
                Dank memes
            </Nav.Link>
            </Nav> */}
        </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}