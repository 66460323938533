import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../utils/Header.tsx";
import Footer from "../utils/Footer.tsx";

export default function NotFound() {
    return (
        <>
        <Header />
        <Container>
            Oops, Page not found :(
        </Container>
        {/* <Footer /> */}
        </>
    );
  }