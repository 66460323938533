import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../utils/Header.tsx";
import Footer from "../utils/Footer.tsx";

export default function ContactUs() {
    return (
        <>
        <Header />
        <Container>
            <h5>Contact US</h5>
            <p>Email - info@codetaught.com</p>
            <p>Phone - 7738245388</p>
            <p>Address - 77, Gokuldham Street, Shiv City, Indore, Pincode - 452012</p>
        </Container>
        {/* <Footer /> */}
        </>
    );
  }