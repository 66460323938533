import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../utils/Header.tsx";
import Footer from "../utils/Footer.tsx";

export default function About() {
    return (
        <>
        <Header />
        <Container>
            <h5>About</h5>
            We are here to help you to learn programming. If you see the world somehow it is connected to
            devices, network, machines. Somewhere all these related to automation, so instead of manual work
            we prefer some kind of automation by help of machines. All these automation run by coding 
            or programming eventually.
            <br/><br/>
            <h6>We uses Emerging Technologies to get quickly a job for you</h6>
            Here we will help you to learn top emerging technologies. These technologies mostly helps you
            to be up to date and also helps you in getting the job.
            <br/><br/>
            <h6>We prepare you for your interview</h6>
            We try to help you to clear your job interview by making your interpersonal skills at advance level.
            We focus on your communication skill, presentation skill, boost up your confidence level.

            Hurry up, we have lot more for you.
        </Container>
        {/* <Footer /> */}
        </>
    );
  }